"use client";

import { useSession } from "next-auth/react";
import Link from "next/link";
import { Suspense } from "react";
import { ActionLink } from "@/shared/action-link";
import { HeaderProfile } from "@/shared/header-profile";

interface UserHeaderProps {
  framesCount?: number;
  mobileView: boolean;
}

export default function UserHeader({ mobileView, framesCount }: UserHeaderProps) {
  const { data: session, status } = useSession();

  if (status === "loading") {
    return null;
  }

  if (mobileView) {
    return session?.user ? (
      <Suspense fallback={<span className="loading loading-dots loading-md"></span>}>
        <HeaderProfile user={session.user} />
      </Suspense>
    ) : (
      <div className="text-sm font-bold">
        <Link href="/sign-up" className="btn btn-primary btn-sm rounded-full">
          Sign Up
        </Link>
      </div>
    );
  }

  return session?.user ? (
    <>
      <div className="mr-2 hidden text-sm font-bold lg:block">
        <ActionLink text="Create Video" link="/create" />
      </div>
      <div className="mr-2 block text-sm font-bold lg:hidden">
        <ActionLink text="Create" link="/create" />
      </div>
      <Suspense fallback={<span className="loading loading-dots loading-md"></span>}>
        <HeaderProfile user={session.user} />
      </Suspense>
    </>
  ) : (
    <>
      <Link
        href="/sign-in"
        className="hidden rounded-md px-4 py-2 text-sm font-bold text-neutral-50 hover:bg-gray-700/50 hover:text-gray-200 lg:block"
      >
        Log In
      </Link>
      <div className="hidden text-sm font-bold md:block">
        <ActionLink text={<>Sign Up &#8212; It&apos;s Free</>} link="/sign-up" />
      </div>
    </>
  );
}
